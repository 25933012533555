export const PLAN_STATUS = {
  RELEASED: '출시',
  UNRELEASED: '미출시'
} as const;

export const PRODUCT_TYPES = [
  { label: 'Game', value: 'GAME' },
  // { label: 'Contents', value: 'CONTENTS' },
  { label: 'Utility', value: 'UTILITY' }
];

export const PROJECT_PAGE_SIZE = 500;
